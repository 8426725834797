@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP');
/* ヘッダー */
.header_container{
   height: 70px; 
   /* position:fixed; */
   width: 100%;
   margin-top: -8px;
   /* z-index: 1; */
}
.header_body{
    display: flex;
    justify-content: space-between;
    background-color: rgb(248,180,0);
}
.header_link{
    text-decoration: none; 
    color:black;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 17px;
}
.header_title_h3{
    font-family: 'Noto Sans JP', sans-serif;
    width: 220px;
}
.header_img{
    margin-left: 10px;
}
.header_nickname{
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 17px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.header_body_left{
    margin-left: 10px;
    margin-top: 20px;
}

.header_body_right{
    display: flex;
    margin-right: 5%;
    margin-top: 4px;
}

.navmune_profile_nickname{
    margin-top: -13px;
    margin-left: 12px;
}
.header_p_underbar{
    color: gray;
    margin-top: -20px;
    opacity: 0.2;
}
.header_navbar_menu{
    display: flex;
    margin-left: 3px;
}
.header_navbar_menu_title{
    margin-top: 1px;
    margin-left: 2px;
}

/* .drawer{
    max-width: 1200px;
    background-color: blue;
} */

@media screen and (max-width: 500px) {
    .header_title_h3{
        display: none;
    }
    .footer_container{
        margin-top: 320px;
        bottom: 0; /*下に固定*/
        text-align: center;
    }
}