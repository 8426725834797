
/* フォントカラー */
.font_color_gray{
    color: gray;
}

.font_color_green{
    color: green;
}

.font_color_skyblue{
    color:skyblue
}

.font_color_blue{
    color: blue
}

.font_color_purple{
    color: purple;
}

.font_color_yellow{
    color: yellow;
}

.font_color_orange{
    color: orangered
}

.font_color_red{
    color: red;
}

