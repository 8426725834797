.font_size_30{
    font-size: 30px;
}

.font_size_20{
    font-size: 20px;
}

.font_size_10{
    font-size: 10px;
}