
.title_container{
    text-align: center;
}
.title_font{
    font-size: 30px;
}

/* 戦績テーブル */
.table {
    min-width: 700px;
    max-width: 1200px;
}
.table_container{
    overflow: auto;
}

.match_record_table{
    display: flex;
    justify-content: center;
}
.results_table_p{
    display: block;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
}
.result_table_p_nickname{
    display:block;
    text-align: center;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 13px;
    font-family: 'Noto Sans JP', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90px;
}

.date_picker_container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

/* 集計ボタン */
.match_total{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.personal_record_container{
    margin-top: 10px;
    margin-bottom: 20px;
}