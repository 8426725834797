/* 対局結果テーブル */
.gameresult_table{
    display: flex;
    width: 100%;
    flex-direction: column;
}
.gameresult_container{
    display: flex;
    /* background-color: aqua; */
    flex-direction: column;
    margin-top: 3px;
    /* border: 1px solid #ccc; */
    background-color: #f0f0f0;
    padding: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0, 0.2);
}
.gameresult_created_date{
    text-align: left;
}

.gameresult_date_string{
    font-style: italic;
    font-size: 12px;
}
.gameresult_info_container{
    display: flex;
    justify-content: space-between;
}

.gameresult_info_body{
    display: flex;
    flex-direction: column;
    width: 95%;
}

.gameresult_body{
    margin-left: 20px;
}
.gameresult_container:hover{
    opacity: 0.7;
 } 
 .table_container{
    overflow: auto;
}

.results_table_p{
    display:inline;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 13px;
    font-family: 'Noto Sans JP', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90px;
}
.results_table_p_blue{
    color: blue;
    display:inline;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 13px;
}
.results_table_p_red{
    color: red;
    display:inline;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 13px;
}
.group_home_results{
    margin-top: -15px;
    height: 350px;
    margin-left: 5px;
}

/* 削除モーダル */
.delete_modal_result{
    margin-top: 10px;
    margin-bottom: 20px;
}
.btn_container{
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
}
.pagenation{
    margin-top: 20px;
}
.pagenationButton{
    margin-left: 5px;
    margin-right: 5px;
}