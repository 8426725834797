@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP');
/* グループホーム */
.group_home_container{ 
    height: 100%;
    width:100%;
    background-color: aliceblue;
}
.match_total{
    text-align: center;
}
.group_home_body_circular_container{
    display: flex;
    justify-content: center;
}
/* GroupInfo */
.groupinfo_container{
    display: flex;
    margin-top: 5px;
    width: 100%;
    /* box-sizing: border-box; */
}
.groupinfo_title{
    display: flex;
    width: 100%;
    /* background-color: #F082DE; */
}
.groupinfo_img{
    width:260px; 
    height:260px;
    border-radius: 20%;
    object-fit: cover;
}
.groupinfo_body_left{
    display: flex;
    justify-content: center;
    width: 55%;
    /* background-color: blue; */
}
.groupinfo_body_right{
    /* margin-left: 50px; */
    display: flex;
    flex-flow: column;
    width: 45%;
    /* background-color: #F082DE; */
}
.groupinfo_title_h2{
    font-family: 'Noto Sans JP', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    font-size: 40px;
}
.group_home_title_p{
    margin-top: 11px;
}

.share_btn{
    margin-top: 40px;
    display: flex;
}
.share_btn_icon{
    margin-left: 6px;
}
.group_home_container_left{
    width: 30%;
}
.grouphome_btn{
    border-bottom:solid 2px rgba(0,0,0,0.5);
    width: 130px;
    margin-top: 7px;
    text-align: center;
}
.grouphome_btn:hover{
    opacity: 0.7;
}
.group_home_menu{
    margin-left: 70px;
    width: 250px;
}
.hgrouphome_menu_btn_h3{
    margin-bottom: -1px;
    font-family: 'Noto Sans JP', sans-serif;
}
.group_home_container_right{
    width: 70%;
}

/* グループホーム　参加 */
.participation_btn_container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.participation_btn_h3{
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 25px;
    border: solid skyblue;
    border-radius: 20em;
    padding: 10px 35px;
}
.participation_btn{
    border: solid;
}
/* メンバー一覧 */
.page_title_group_member{
    text-align: left;
    display: flex;
}
.update_rate_btn{
    margin-top: 20px;
    margin-left: 10px;
}
/* ゲーム結果 */
.match_recode_table{
    display: flex;
    justify-content: center;
}
.selectdate_container{
    display: flex;
    justify-content: center;
}
.selectdate_body{
    display: flex;
    justify-content: center;
}
.result_matchrecode{
    display: flex;
    justify-content: center;
}
.selectdate_container_h3{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
}
.gameresults_btn_container{
    display: flex;
    margin-left: 30px;
    width: 200px;
    justify-content: space-between;
}
.gameresults_circular{
    margin-top: 10px;
    text-align: center;
}
.matchresult_title{
    text-align: center;
    margin-bottom: 5px;
}
.results_table_not_exist_div{
    text-align: center;
    margin-top: 10px;
}
.result_page_body{
   text-align: center;
}

/* グループメンバー */
.member_main_container{
    display: flex;
    justify-content: center;
    /* background-color: aqua; */
}
.member_main_body{
    width: 90%;
}
.page_title_select_member{
    text-align: left;
}
.member_container{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}
.member_body{
    margin-top: 5px;
    width: 100px;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: thick double #32a1ce;
    height: 123px;
}
.member_body_container{
    z-index: 1;
    
    /* background-color: aquamarine; */
}
.member_avater{
    margin-left:20px;
}
.member_user_btn:hover{
    opacity: 0.7;
}
.member_nick_name{
    font-size: 14px;
    font-family: 'Noto Sans JP', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 105px;
}
.position{
    z-index: 2;
    opacity: 0.5;
    font-size: 50px;
    background-color: #32a1ce;
    margin-top: -80px;
}

.member_rate{
    font-size: 15px;
    font-family: 'Noto Sans JP', sans-serif;
    width: 105px;
    line-height:0.95em;
	font-weight:bold;
	/* color: #01579B; */
	text-shadow: 0 0 0.2em rgba(255,255,255,1);
}
/* レートごとの色 */
.font_color_gray{
    color: gray;
}

/* メンバー詳細 */
.membder_detail_container{
    /* background-color: #32a1ce; */
    margin-bottom: 5px;
}
.member_detail_profile_conainer{
    /* background-color: blueviolet; */
    display: flex;
    padding: 10px;
    /* border-radius: 10px; */
    /* background-color: red; */
    border-bottom: 4px solid #eeeeee;
    box-sizing: border-box;
    /* width: 90%; */
}
.member_detail_profile_body_left{
    width: 40%;
}
.member_detail_profile_body_right{
    /* margin-left: 30px; */
    width: 60%;
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
}
.member_detail_nick_name{
    font-family: 'Noto Sans JP', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-weight:bold;
	/* color: #01579B;
	text-shadow: 0 0 0.2em rgba(255,255,255,1); */
    text-align: left;
}

.member_detail_rank{
    font-size: 55px;
    /* margin-left: -30px; */
    font-family: 'Noto Sans JP', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: left;
}

.member_detail_progress_container{
    display: flex;
}
.member_detail_progress_number{
    margin-left: 10px;
    margin-top: -5px;
}
.member_detail_record_container{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    width: auto;
    box-sizing: border-box;
}
.member_detail_chart_conainer{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
}
.member_detail_maxmin_score_container{
    /* background-color: aqua; */
    display: flex;
    padding: 0px 10px;
    width: auto;
    justify-content: center;
}
.member_detail_maxmin_score_body{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 30px;
}
.member_detail_grade_container{
    /* background-color: burlywood; */
    width: auto;
    margin-top: 15px;
}
.member_detail_grade_container{
    display: flex;
    justify-content: space-between;
    padding: 0px 30px
}
.member_detail_grade_body_left{
    text-align: left;
}
.member_detail_grade_body_right{
    text-align: left;
}


/* GAME セレクト */
.game_selected_profile{
    opacity: 0.5;
    background-color: grey;
}
.game_start_btn{
    text-align: center;
    margin-top: 20px;
}
.gameresults_input_panel{
    display: flex;
    margin-top: 7px;
    margin-bottom: 7px;
}
.gameresults_input_panel_player{
    width: 350px;
    display: flex;
}
.gameresults_input_panel_direction{
    margin-top: 5px;
    margin-left: -5px;
    margin-right: 3px;
}
.gameresults_input_panel_nickname{
    margin-top: -17px;
    margin-left: 3px;
}
.game_nickname{
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 15px;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.edit_game_result_field{
    margin-top: -30px;
}
/* 個人成績 */
.radar_chart{
    display: none;
}

/* 設定モーダル */
.leavegroup{
    font-size: 8px;
    margin-left: 170px;
    margin-top: 15px;
}
.leavegroup:hover{
    background-color: tomato;
    opacity: 0.8;
}
.leavegroup_p_c{
    font-size: 5px;
}
.leavegroup_container{
    display: flex;
    margin-top: 20px;
}
.leavegroup_body{
    margin-left: 40px;
}
.groupsetting_modal_img{
    border: solid;
}
.groupsetting_modal_img:hover{
    opacity: 0.7;
}
.groupsetting_btn{
    text-align: center;
    margin-top: 5px;
}
/* グループ参加 */
.group_home_modal_container{
    text-align: center;
}
.group_home_modal_btn{
    margin-top: 50px;
}
@media screen and (max-width: 800px) {
    .group_home_body_top{
        margin-left: 10px;
    }
    .group_home_body{
        display:flex;
        /* flex-flow: column; */
    }
    .groupinfo_title{
        display: flex;
        justify-content: center;
    }
    .groupinfo_container{
        display:flex;
        /* flex-flow: column; */
    }
    .groupinfo_body_right{
        margin-left: 20px;
        display: flex;
        flex-flow: column;
    }
    .group_home_container_left{
        margin-left: -6px;
        width: 100%;
    }
    .grouphome_btn{
        border-bottom:solid 2px rgba(0,0,0,0.5);
        width: 100px;
        text-align: center;
        margin-left: 10px;
    }
    .group_home_menu{
        display: flex;
        justify-content: center;
        width: 100%;
        margin-left: 0px;
    }
    .group_home_container_right{
        margin-top: 28px;
        width: 100%;
    }
    .results_table_p{
        display: block;
    }
    /* 対局開始 */
    .game_main_container{
        width: 100%;
        text-align: center;
        margin-left: 0;
    }
    .game_container{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    /* 記録 */
    .match_recode_table{
        display: flex;
        justify-content: left;
        overflow: auto; 
        white-space: nowrap; 
        width:100%;
    }
    .result_matchrecode{
        display: flex;
        justify-content: left;
        overflow: auto; 
        white-space: nowrap; 
        width:100%;
    }
}
@media screen and (max-width: 800px) {
    .groupinfo_img{
        max-width: 180px;
        max-height: 180px;
        margin-top: 5px;
        /* border-radius: 50%; */
        object-fit: cover;
    }
    .groupinfo_body_right{
        display: flex;
    }
    .groupinfo_title_h2{
        font-family: 'Noto Sans JP', sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 270px;
        font-size: 30px;
    }
    .group_home_title_p{
        margin-top: -1px;
    }
    /* .groupinfo_container{
        display: flex;
        margin-top: 5px;
        flex-flow: column;
        justify-content: center;
    } */
    .groupinfo_title{
        margin-top: 13px;
        margin-left: -4px;
    }
    .results_table_p{
        display: block;
        font-size:8px;
        font-family: 'Noto Sans JP', sans-serif;
        /* max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
    }
    .match_recode_table{
        display: flex;
        justify-content: left;
        overflow: auto; 
        white-space: nowrap; 
        width:100%;
    }
    .result_matchrecode{
        display: flex;
        justify-content: left;
        overflow: auto; 
        white-space: nowrap; 
        width:100%;
    }
    .label_y_p_3{
        margin-top: 30px;
        font-size: 15px;
    }
    .label_y_p_4{
        margin-top: 30px;
        font-size: 15px;
    }
    /* メンバー一覧 */
    .game_main_body{
        margin-left: 25px;
    }
}